@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@600&family=Inter&family=Roboto&display=swap');

* {
  font-family: 'Inter', sans-serif;
}



